@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap");

* {
  font-family: "Poppins";
}

html,
body {
  height: 100%;
  width: 100%;
}

/* navbar */

.nav-item .active {
  background-color: transparent !important;
  /* text-decoration-line: underline; */
  text-underline-position: below;
  text-decoration-style: solid;
  text-decoration-color: gray;
  text-decoration-thickness: 15%;
  text-underline-offset: 50%;
}

.card:hover {
  -webkit-box-shadow: 0 2px 4px rgba(72, 78, 85, 0.6);
  box-shadow: 0 2px 4px rgba(72, 78, 85, 0.6);
}

/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 80px;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
